@import "/src/styles/base/variables";
.appsItem {
  padding: 8px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_top {
    display: flex;
    align-items: center;

    img {
      margin: 0 15px;
      height: 34px;
    }
  }

  &_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
    border-right: 1px solid #d8dde2;
  }

  &_right {
    font-size: 1rem;
    padding: 0 10px 0 15px;
  }
}
.config_Label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  display: block;
  margin-bottom: 10px;

  &_mar {
    margin-bottom: 20px;
  }
}

body {
  background-color: $body-bg !important;
}
.page {
  padding-top: 2rem;
}

.leftBanner {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  min-height: 400px;
  padding: 2rem 0rem 2.5rem;
  margin-bottom: 1rem;

  &_img {
    width: 210px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8dde2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    margin: 0 auto 2rem;
    position: relative;

    &:hover {
      .edit {
        display: initial;
      }
    }
    .edit {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
      display: none;
    }

    .img {
      width: 185px;
      height: 90px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &_name {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #29394d;
    text-transform: uppercase;
    padding: 0 1rem;
    position: relative;

    &:hover {
      .edit {
        opacity: 1;
      }
    }

    .edit {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 0px;
    }
  }
  ul {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    // border-top: 1px solid #f1f2f4;
    margin-top: 2rem;
    // padding: 0rem 1rem;
    span {
      color: #29394d;
    }

    li {
      padding: 0rem 1rem;
      color: #6d7f92;

      &:hover {
        .content_li .value svg {
          opacity: 1;
        }
      }

      .content_li {
        border-top: 1px solid #f1f2f4;
        padding: 1rem 0rem;
        .value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .edit {
            cursor: pointer;
          }
          .valueContent {
            width: fit-content;
            .approval_num {
              display: block;
              margin-top: 0.5rem;
              input {
                border: none;
                letter-spacing: 0.15rem;
                background-color: transparent;
              }
            }
          }
          svg {
            opacity: 0;
          }
        }
      }
      .last {
        border-bottom: 1px solid #f1f2f4;
      }
    }
    .active_li {
      background-color: #f8f9fa;
    }
  }
}
.buttons {
  // margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 2rem;
    width: 7.125rem;
    background: #06d9b1;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }
  .cancelBtn {
    width: 5.5rem;
    background: #f1f2f4;
    color: #6d7f92;
  }
}

.avatarBtns {
  padding: 0 1rem 1.5rem;
  margin-top: -0.5rem;
}
.subscriptionDetails {
  background: #edfaf5;
  border: 1px solid #dcf5ec;
  border-radius: 5px;
  padding: 1.25rem;
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #29394d;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
  }
  > div {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;
    margin-bottom: 1.5rem;
    span {
      font-weight: 500;
      display: block;
      margin-top: 5px;
    }
    .icon {
      width: 34px;
      height: 34px;
      background: #06d9b1;
      border: 2px solid #f1f2f4;
      border-radius: 50px;
      margin-right: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      i:before {
        color: #fff;
      }
    }
  }
}

.content {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 2rem;
}

.title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.25rem;
}

.box {
  background: #ffffff;
  border: 1px solid #d8dde2;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 12px;
  line-height: 14px;

  &_top {
    padding: 20px;
    background: #f3faff;
    border-radius: 8px 8px 0px 0px;
  }

  &_content {
    padding: 20px;
  }

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.appsItem {
  padding: 8px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_top {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
  &_topwatch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 15px;
    border-right: 1px solid #d8dde2;

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
  &_content {
    margin-top: 2rem;
  }
}

.watch {
  display: flex;
  flex-direction: column;
  line-height: 14px;
}

.watch_tag {
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  text-transform: capitalize;
  color: #fff;
  background: #e02d5c;
  border-radius: 3px;
  padding: 2px 4px;
}

.watch_link {
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #6d7f92;
  margin-right: 15px;
}

.portalBloc {
  padding: 1rem;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
    }

    img {
      margin: 0 15px;
      height: 34px;
    }
  }
}

.inModal {
  border: none;
  margin: 0;
  box-shadow: none;
  border-radius: none;
}

.mapbox {
  position: relative;
  z-index: 0;
  height: 300px;
  margin-bottom: 1rem;
}

.schedule {
  background-color: #fff;
  color: #3c4e64;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;

  &_row {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    border-bottom: 1px solid #f1f2f4;
  }

  &_header {
    font-weight: 500;
    text-align: center;
  }

  &_day {
    width: 20%;
    text-align: center;
  }

  &_hours {
    width: 32%;
  }

  &_action {
    width: 16%;
  }

  &_input {
    display: flex;
    align-items: center;

    span {
      margin: 0 10px;
    }

    input {
      background: #ffffff;
      border: 1px solid #b2bcc6;
      border-radius: 4px;
      padding: 7px 5px;
      font-size: 12px;
      line-height: 14px;
      color: #18a0fb;
      width: 50px;

      &:hover {
        border-color: #dfe2e6;
      }

      &:focus {
        border: 1px solid #18a0fb;
        background: #f3faff;
        box-shadow: none;
      }
    }
  }

  &_nowork {
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      margin-left: 0.75rem;
    }
  }

  &_btn {
    border: 1px solid #c7e8fe;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    display: block;
    cursor: pointer;
    line-height: 14px;
    text-align: center;
    padding: 10px 5px;
    margin: 0 1rem 0 0;
    color: #18a0fb;

    &:hover {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}

.services {
  position: relative;
  .list {
    max-height: 350px;
    overflow: scroll;
    margin-top: 1rem;
  }
}

.addService {
  border-radius: 5px;
  cursor: pointer;
  height: 160px;
  width: 133px;
  background: #ffffff;
  border: 1px solid #18a0fb;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #18a0fb;
    font-size: 50px;
  }
  &:hover {
    background: #18a0fb9a;
    i {
      color: #fff;
    }
  }
}

.mediaTitle {
  margin: 10px 10px 0;
}

.addMedia {
  // margin-top: 1rem;
  margin-left: auto;
}

.addBox {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: #18a0fb;
  background: #ffffff;

  &_icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #c7e8fe;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    font-size: 14px;

    i {
      font-weight: bold;
      transition: all 0.2s ease-in-out;
    }
  }
}

.rotate {
  transform: rotate(45deg);
}

.widgetBody {
  display: none;
  border-radius: 5px;
  border: 1px solid #c7e8fe;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  padding: 1rem;
  margin-top: 1rem;
  background-color: #f8f9fa;

  h3 {
    color: #29394d;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }

  h3.apps {
    border-top: 1px solid #e1e1e1;
    margin-top: 1rem;
    padding-top: 1.5rem;
  }
}
.widgetContainer {
  display: flex;
  flex-wrap: wrap;
}
.widgetBloc {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #f8f9fa;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  &_icon {
    font-size: 18px;
    margin-right: 1rem;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &_label {
    margin-bottom: 5px;
  }
}
.disableBtn {
  opacity: 0.7;
}
.mediasList {
  max-height: 400px;
  overflow-y: scroll;
  margin: 0 -10px;
  margin-top: 1rem;
  padding: 0 10px;
}
.mediaCont {
  padding: 0 5px;
  margin-bottom: 10px;
}
.uploadingMedia {
  position: relative;
  height: 8.75rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: $v2-box-shadow;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  border-radius: 5px;

  .uploadingMediaOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.switchGroup {
  margin: 1.313rem 0;
}
.filter {
  padding-left: 1rem;
  border-right: 1px solid #d8dde2;
}
.configLabel_cont {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  .tooltip {
    color: #6d7f92;
    cursor: pointer;
  }
}
.configLabel {
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  margin-right: 0.25rem;
}
.switches {
  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    .checkbox {
      cursor: pointer;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 100%;
      background: #f8f9fa;
      border: 2px solid #b2bcc6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;
      &_active {
        background: #18a0fb;
        border: 2px solid #18a0fb;
      }
    }
  }
}

.marginBottom {
  margin-bottom: 1rem;
}
.separator {
  border: 1px solid #d8dde2;
}
.specialtyContent {
  padding: 0 0.25rem;
  padding-top: 1.25rem;
  .title {
    color: #29394d;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  .subtitle {
    font-size: 0.75rem;
    color: #6d7f92;
  }
  .typesList {
    margin-top: 1.5rem;
    margin-bottom: 1.375rem;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      width: 11rem;
      height: 2.5rem;
      padding: 0 0.5rem;
      background: #f1f2f4;
      border-radius: 5px 5px 0px 0px;
      font-weight: 500;
      font-size: 0.875rem;
      color: #6d7f92;
      margin-right: 0.636rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background-color: #18a0fb;
        color: #fff;
      }
    }
  }
}
.specialty {
  padding: 1.25rem 0.25rem;
  border-right: 1px solid #d8dde2;
  margin-bottom: 1rem;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    line-height: 1rem;
    i {
      font-size: 24px;
    }
    .name {
      font-weight: 500;
      font-size: 0.875rem;
      display: block;
      margin-left: 0.5rem;
    }
  }
}
.specialtiesList {
  .subSpec {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f1f2f4;
    font-size: 0.75rem;
    line-height: 0.875rem;
    &:last-child {
      border-bottom: none;
    }
    .star {
      cursor: pointer;
      opacity: 0;
      margin-left: auto;
    }
    span {
      display: block;
      cursor: pointer;
      max-width: 11rem;
    }
    .active {
      opacity: 1;
      color: gold;
    }
    &:hover {
      .star {
        opacity: 1;
      }
    }
  }
}
.noBorder {
  border-right: none;
}

.checkbox,
.radio {
  cursor: pointer;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 5px;
  background: #f8f9fa;
  border: 2px solid #b2bcc6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  &_active {
    background: #18a0fb;
    border: 2px solid #18a0fb;
  }
}
.radio {
  border-radius: 100%;
}
.checkbox {
  margin: 0 0.25rem;
}
.public_domain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_title {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #3c4e64;
    display: block;
    margin-right: 0.5rem;
    margin-bottom: 0;
    width: 100px;
  }
  .mb {
    margin-bottom: 10px;
  }
  .flex {
    display: flex;
    align-items: center;
    width: 100%;
  }
  input {
    height: 35px;
    width: 250px;
    border-radius: 5px;
    color: #29394d;
    background-color: #f8f9fa;
    border: 1px solid #b2bcc6;
    font-size: 14px;
    padding: 2% 1%;
  }
  span {
    font-size: 14px;
    margin-right: 2%;
  }
  svg {
    cursor: pointer;
    color: #3c4e64;
  }
  .error {
    color: red;
    margin-top: 0.5rem;
  }
  .suggestion {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 0.5rem;
    span {
      cursor: pointer;
      color: #18a0fb;
      margin-left: 0.5rem;
    }
  }
}

.design_switch {
  display: flex;
  align-items: center;

  margin-top: 15px;
  border-radius: 24px;
  .flex {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }
  .btn {
    padding: 0.75rem 2rem;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &_active {
      background-color: #18a0fb;
      color: #fff;
    }
    &_disabled {
      cursor: not-allowed;
      background-color: #f8f9fa;
    }
  }
}

.configLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  display: block;
  margin-bottom: 10px;

  &_mar {
    margin-bottom: 20px;
  }
}
.formInput {
  display: block;
  width: 100%;
  padding: 2%;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
  font-size: 14px;
}
.descriptionInput {
  height: 70px !important;
  padding: 2%;
  display: block;
  width: 100%;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
  margin-bottom: 0px;
  resize: none;
  font-size: 14px;
  &:focus {
    background-color: #f8f9fa;
    box-shadow: none;
    border: 1px solid #2495e1;
  }
}

.descriptionLabel {
  margin-top: 15px;
}

.testimonial_content {
  margin-top: 1rem;
}

.mediasList {
  max-height: 400px;
  overflow-y: scroll;
  margin: 0 -10px;
  margin-top: 1rem;
  padding: 0 10px;
}
.mediaCont {
  padding: 0 5px;
  margin-bottom: 10px;
}

.dropZone {
  position: relative;
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #3c4e64;
  box-sizing: border-box;
  border-radius: 7.5px;
  font-size: 14px;
  padding: 0 2rem;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.numberForm {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .title {
    width: 30%;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .description {
    flex-grow: 1;
    margin-bottom: 0;
  }
  button {
    margin-left: 0.5rem;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    svg {
      height: 1.5rem;
    }
  }
}

.pageContent {
  margin-top: 1rem;
  border-top: 1px solid #6d7f92;
  padding-top: 1rem;
  &_title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: normal;
  }
  .section {
    margin: 1rem 0.5rem 0;
    border-top: 1px solid #6d7f92;
    padding: 0.5rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      .name {
        font-weight: 500;
        text-transform: capitalize;
        font-size: 0.75rem;
      }
    }
  }
}
.video {
  margin-top: 1rem;
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 0.5rem;
    }
  }
  .video_preview {
    width: 100%;
    margin-top: 1rem;
  }
}

.features_section {
  margin-top: 1rem;
  .flex {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    .arrowBtn {
      margin-left: 0.5rem;
      svg {
        transform: rotate(180deg);
      }
      &_rotate {
        svg {
          transform: rotate(360deg);
        }
      }
    }
  }
  .title {
    font-size: 1rem;
  }
  .categories_list {
    .category {
      margin-top: 1rem;
      padding: 1rem 0.5rem;
      border-top: 1px solid #b2bcc6;

      .features {
        .feature {
          margin-top: 1rem;
          padding: 1rem 0.5rem;
          border: 1px solid #b2bcc6;
          border-radius: 0.5rem;
        }
      }
    }
  }
}
.packs_section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #3c4e64;

  .title {
    font-size: 1rem;
  }

  .pack {
    margin-top: 1rem;
    padding: 1rem 0.5rem;
    border-top: 1px solid #b2bcc6;
  }

  .priceForm {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .row {
      width: 30%;
      margin-right: 1rem;
      input {
        padding-left: 0.75rem;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    .arrowBtn {
      margin-left: auto;
      svg {
        transform: rotate(180deg);
      }
      &_rotate {
        svg {
          transform: rotate(360deg);
        }
      }
    }
  }
  .features {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    .catName {
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      padding: 1rem 0.5rem;
    }
    .feature {
      margin-top: 0.5rem;
      padding: 0.75rem;
      border: 1px solid #b2bcc6;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 14px;
        font-weight: 500;
      }
      .actions {
        width: fit-content;
        display: flex;
        align-items: center;
        .value {
          width: 120px;
          margin-left: 0.5rem;
          padding-left: 0.75rem;
        }
      }
    }
  }
}
.btnSendRequest {
  color: #fff;
  background: #048de9 linear-gradient(180deg, #289deb, #048de9) repeat-x;
  border-color: #0485dc;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  text-wrap: nowrap;
  border-radius: 5px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.requestSent {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .icon {
    width: 27px;
    height: 25px;
    background: #8ef0bb;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #25cf73;
  }
  span {
    text-wrap: nowrap;
    color: #13b455;
  }
}
.orSpan {
  font-size: 14px;
  margin-right: 2%;
  border-left: 1px solid;
  margin-left: 10px;
  padding-left: 10px;
}
.themePanel {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    li {
      width: 26px;
      height: 26px;
      border-radius: 4px;
      border: 4px solid white;
      cursor: pointer;
    }
  }
}
.defaultTheme {
  background-color: #000;
  &.selected,
  &:hover {
    border-color: #000;
  }
}
.redTheme {
  background-color: #e20b3f;
  &.selected,
  &:hover {
    border-color: #e20b3f;
  }
}
.purpleTheme {
  background-color: #aa0be2;
  &.selected,
  &:hover {
    border-color: #aa0be2;
  }
}
.blueTheme {
  background-color: #0a31ff;
  &.selected,
  &:hover {
    border-color: #0a31ff;
  }
}
.greenTheme {
  background-color: #4eca02;
  &.selected,
  &:hover {
    border-color: #4eca02;
  }
}
.orangeTheme {
  background-color: #fa8b09;
  &.selected,
  &:hover {
    border-color: #fa8b09;
  }
}
.yellowTheme {
  background-color: #e8b912;
  &.selected,
  &:hover {
    border-color: #e8b912;
  }
}
