@mixin titles {
  color: #29394d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::first-letter {
    text-transform: capitalize !important;
  }
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999 !important;
  background-color: rgba(41, 57, 77, 0.4);
  overflow-y: scroll;
}

.modalContent {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  top: 19%;
  background: transparent;
}

.container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 8.5%;
  width: 1129px;

  .modalHeader {
    display: flex;
    max-width: 100%;
    width: 100%;
    padding: 20px;
    left: 0%;
    top: 0%;
    background: #f3faff;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 2px solid #c7e8fe;

    .headerTitle {
      width: 100%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      margin: 0;
      font-size: 20px;
      line-height: 23px;
      color: #18a0fb;
    }

    .close {
      height: 36px;
      width: 36px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      color: #3c4e64;
      background: #ffffff;
      border: 1px solid #f1f2f4;
      border-radius: 50%;
      margin: -3.2% -3% 0 0;
    }
  }

  .modalBody {
    &.first_slide {
      display: flex;
      justify-content: center;
      padding: 2rem 0rem 2rem 3rem;
    }

    &.second_slide {
      padding: 0;
    }

    .plans {
      border: 1px solid #d8dde2;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      //align-items: center;
      text-align: center;
      margin-right: 8px;
      width: 184px;

      &.active {
        border: 1px solid #18a0fb;

        .plans_header {
          background: #c7e8fe;

          .offer {
            background: #f3faff;
          }
        }

        .triangle {
          background: #c7e8fe;
        }
      }

      .plans_header {
        background: #f8f9fa;
        padding: 16px 13px 0px 13px;
        width: 182px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h3 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #000000;
          height: 4.96rem;
          display: flex;
          justify-content: center;
          align-items: center;

        }

        .offer {
          display: inline-block;
          background: #f1f2f4;
          border-radius: 3px;
          padding: 3px 9px;
          margin: 10px 0;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          color: #29394d;
        }

        .period {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          text-align: center;
          color: #29394d;
        }

        .check_radio {
          margin-top: 19px;

          input {
            width: 25px;
            height: 25px;
          }

          input:checked {
            border: 6px solid black;
            cursor: pointer;
          }

          input:disabled {
            cursor: not-allowed;
          }
        }
      }

      .triangle {
        clip-path: polygon(100% 0, 0 0, 50% 100%);
        background: #f8f9fa;
        height: 30px;
        width: 100%;
        margin-bottom: 16.9px;
      }

      .free_space {
        height: 39px;
      }

      li {
        background: #f8f9fa;
        padding: 10px 8%;
        height: 56px;
        display: grid;
        align-items: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-transform: lowercase;

        color: #29394d;

        p {
          &::first-letter {
            text-transform: capitalize !important;
          }
        }

        .close_icon {
          color: #fe3745;
        }

        .check_icon {
          color: #02af8e;
        }
      }
    }

    li {
      margin: 1px 0 2px 0;

      & h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #29394d;
      }

      &.desc {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #6d7f92;
        height: 131px !important;
        text-overflow: ellipsis;
        line-height: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        margin-bottom: 1rem;
      }

      .state_choice {
        color: #02af8e;
      }

      .state_predefined {
        color: #e27338;
      }
    }

    .preferences {
      text-align: end;

      h4 {
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 47px;
        height: 39px;
        color: #6d7f92;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }

      .options {
        background-color: #f8f9fa;
        padding: 4% 17px 4% 19px;
        height: 56px;
        color: #29394d;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 240%;

        &::first-letter {
          text-transform: capitalize !important;
        }

        i {
          margin-left: 12px;
          margin-top: 1px;
        }
      }
    }

    .config {
      display: flex;
      justify-content: center;

      >div {
        border-right: 1px solid #f8f9fa;
        padding: 1.5rem;

        span {
          font-family: "Roboto";
          font-style: normal;
          display: flex;
        }

        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            @include titles;
            margin-right: 1rem;
          }

          .title_link {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 12px;

            text-decoration-line: underline;

            color: #6d7f92;
            cursor: pointer;
            width: 101px;
          }
        }

        .sub_title {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
          padding: 0.4rem 0 1.5rem 0;
        }

        .space {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1rem;
        }
      }

      .mail_list {
        width: 29.8%;
        height: 457px;
        background-color: #f8f9fa;

        .title {
          @include titles;
        }

        .container_select {
          justify-content: space-between;
          flex-wrap: nowrap;
          gap: 7px;

          .sender_select {
            .sender_title {
              color: var(--Dark-1, #29394D);
              font-family: "Roboto";
              font-size: 12px;
              font-weight: 500;
              margin-top: 10px;
            }
          }

          .sender_add_icon {
            border: 1px solid #B2BCC6;
            padding: 5px;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        .separator {
          margin: 0.7rem 0;
        }

        .senders_count {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
        }

        .senders_list {
          height: 280px;
          overflow-y: auto;

          .info {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;

            color: #6d7f92;
            float: right;
            cursor: pointer;
            margin: 0 0 0.6rem 0px;
            background: #fcfaf2;
            border-right: 4px;
            padding: 0.4rem;

            &::first-letter {
              text-transform: capitalize !important;
            }
          }

          .collab_block {
            margin-top: 0.7rem;
            background: #ffffff;
            border: 1px solid #e1e4e8;
            border-radius: 5px;
            padding: 0.7rem;
            display: flex;
            flex-direction: column;

            span {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }

            .block_name {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.5rem;

              .name {
                line-height: 14px;
                color: #29394d;
                margin-bottom: 0.4rem;
              }

              i {
                display: none;
                align-items: center;
                font-size: 0.7rem;
                font-weight: 900;
                color: #eb5757;
                border-left: 1px solid #f1f2f4;
                padding-left: 5px;
              }
            }

            .block_email {
              box-sizing: border-box;

              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 9px 12px;

              width: 100%;
              min-height: 36px;
              background: #f1f2f4;
              border-radius: 4px;

              .email {
                line-height: 14px;

                color: #29394d;
                line-break: anywhere;
                width: 89%;
              }
            }

            .warning {
              font-size: 10px;
              line-height: 12px;
              color: #ea8124;
              display: block;
              text-align: end;
              width: 100%;
              margin: 0.5rem 0;

              &::first-letter {
                text-transform: capitalize !important;
              }
            }

            .refresh {
              font-size: 10px;
              line-height: 12px;
              text-decoration-line: underline;

              color: #6d7f92;
              display: block;
              text-align: end;
              width: 100%;
              margin-top: 0.7rem;
              cursor: pointer;

              &::first-letter {
                text-transform: capitalize !important;
              }
            }

            button {
              height: 36px;
              width: 100%;
              border: none;
              border-radius: 4px;
              font-weight: 500;
              font-size: 0.75rem;
              line-height: 0.75rem;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;

              cursor: pointer;

              &.confirm_btn {
                background: #18a0fb;

                &::first-letter {
                  text-transform: capitalize !important;
                }
              }

              &.load_btn {
                background: rgba(6, 217, 177, 0.1);
              }
            }

            .form_collab_label {
              padding: 9px 0;
            }

            .form_collab_input {
              height: 36px;
            }

            .block_close {
              display: flex;
              justify-content: flex-end;

              i {
                visibility: hidden;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 0.7rem;
                font-weight: 900;
                color: #eb5757;
                border-left: 1px solid #f1f2f4;
                padding-left: 5px;
              }
            }

            &:hover {
              .block_name i {
                display: flex;
              }

              .block_close i {
                visibility: visible;
              }
            }

            .btn_add_collab {
              cursor: not-allowed;
            }
          }
        }
      }

      .config_details {
        width: 41%;
        height: 445px;
        margin-left: 1.5rem;
        padding: 1.5rem 1rem !important;

        .config_block {
          >div {
            display: flex;
            align-items: center;
            padding: 0.5rem 0;
          }

          .show_log {
            margin-right: 0.5rem;
          }

          .details_title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 240%;
            text-align: right;
            color: #29394d;
            width: 27%;
            float: right;
            display: block;
            margin-right: 1rem;

            &::first-letter {
              text-transform: capitalize !important;
            }
          }

          .automatic_check {
            align-items: center;
            margin-left: 30.5%;

            .styled_checkbox {
              display: none;
            }

            label {
              display: inline-block;
              cursor: pointer;
              position: relative;
              padding-left: 25px;
              margin-right: 15px;
              user-select: none;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
              color: #29394d;

              &::first-letter {
                text-transform: capitalize !important;
              }
            }

            label:before {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 2px solid #b2bcc6;
              border-radius: 5px;
            }

            .styled_checkbox:checked+label:before {
              content: "\2713";
              text-align: center;
              font-size: 15px;
              line-height: 20px;
              color: #fff;
              background-color: #0275d8;
              border-color: #0275d8;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .title {
            @include titles;
          }

          .separator {
            margin: 0.5rem 0 0 0 !important;
          }
        }
      }

      .config_dates {
        width: 27%;

        .title {
          @include titles;
        }

        .calendar {
          margin-top: 8%;
          display: flex;
          justify-content: center;

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #29394d;
            margin-right: 1rem;
            background: #f3faff;
            padding: 3px 9px;
          }
        }
      }
    }
  }

  .separator {
    border-top: 1px solid #d8dde2;
    margin: 0 0rem 1rem 0rem;
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    padding: 0 2rem 1.2rem 2rem;

    button {
      cursor: pointer;
      text-transform: uppercase;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      border-radius: 5px;
      height: 44px;
    }

    .cancelButton {
      background: #f8f9fa;
      color: #6d7f92;
      border: 1px solid rgba(109, 127, 146, 0.5);
      width: 125px;
    }

    .right_block {
      display: flex;

      .configureButton {
        border: 1px solid #06d9b1;
        box-sizing: border-box;
        background: #ffffff;
        color: #02af8e;
        margin-right: 1rem;
      }

      .saveButton {
        border: 1px solid #0ebf9e;
        background: #0ebf9e;
        color: #ffffff;
      }
    }
  }
}

.modalContentAfterOpen {
  .container {
    top: 0;
    opacity: 1;
  }
}

.modalContentBeforeClose {
  .container {
    top: 50%;
    opacity: 0;
  }
}