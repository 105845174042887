.content {
  background: #fff;
  box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 1rem;
  color: #29394d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top_actions {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  height: max-content;
  padding-bottom: 15px;
  margin-top: 2rem;
}
.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 6px;
    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
  }

  .associate_row {
    background-color: #dcf5ec;

    .associate_td {
      padding-left: 28px;
      padding-right: 0;
    }
  }

  .selectRowAction {
    background-color: #f3faff;

    td {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 6px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1005 !important;
  background-color: rgba(41, 57, 77, 0.4);
}
.modalContentAfterOpen {
  .modal {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .modal {
    top: 30%;
    opacity: 0;
  }
}
.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;

  width: 500px;
  opacity: 0;
  background: #f8f9fa;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 60px;
  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;
  color: #2495e1;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  padding: 0.5rem 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 1rem;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapbox {
  position: relative;
  z-index: 0;
  height: 500px;
  margin: 1rem 0 2rem;
}

.loadMore {
  width: 200px;
  border-radius: 30px;
  margin: 2rem auto 1rem;
  padding: 10px 5px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #18a0fb;
  border: 1px solid #f1f2f4;

  &:hover {
    background-color: #f1f2f4;
  }
}

.customMarker {
  transition: all 200ms ease-in-out;
}

.filterCheckbox {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 25px;

  &_item {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  &_itembtm {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}

.sperator {
  margin-top: 20px;
  margin-bottom: 20px;
}
.groups {
  margin-bottom: 1rem;
  min-height: 32px;

  span {
    float: left;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}
.groupName {
  display: block;
  width: max-content;
  border: 1px solid #b2bcc6;
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  float: left;
}

.inputGroup {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  input {
    width: 90%;
    border: none;
    outline: none;
    margin: auto;
    color: #3c4e64;
  }
  .searchResults {
    margin-top: 0.25rem;
    position: absolute;
    top: 100%;
    left: 5%;
    background-color: #fff;
    width: 90%;
    border: 1px solid #b2bcc6a3;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    li {
      padding: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: #f3faff;
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


.selectAction {
  color: #18a0fb;
  cursor: pointer;
  margin-left: 10px;
}
