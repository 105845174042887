.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;

  &.hide {
    display: none;
  }
}

.sidebar {
  width: fit-content;
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  bottom: 0;
  //left: 42%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;
  overflow-x: hidden;

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.close {
  text-align: right;
  cursor: pointer;
}


@media screen and (max-width: 39.9375em) {
  .sidebar {
    left: 0%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sidebar {
    left: 40%;
  }
}

