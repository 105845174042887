.content {
  max-width: 700px;
  margin: 2rem auto 0;
}
.top {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: #6d7f92;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
    margin-bottom: 10px;
  }

  &_right {
    padding: 18px;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    width: 40%;
    margin-left: 50px;
  }
}

.savings {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 22px;
  color: #18a0fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.period {
  &_item {
    &_box {
      position: relative;
      padding: 14px 21px;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      // min-height: 165px;
    }
  }

  &_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e4e8;
    margin-bottom: 10px;
  }

  &_price {
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 5px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &_txt {
    font-size: 14px;
    line-height: 22px;
    color: #6d7f92;
  }
}

.formula {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #18a0fb;
  margin-bottom: 10px;
  min-height: 54px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: block;
  }
}
.price_barred {
  color: #de4848;
  text-decoration: line-through;
}
.total {
  margin: 10px 0;
  background: #f3faff;
  border-radius: 5px;
  padding: 28px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  span {
    font-size: 24px;
    color: #18a0fb;
  }

  small {
    color: #18a0fb;
    font-size: 14px;
  }
}
